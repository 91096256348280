import {
  Avatar,
  Divider,
  InputNumber,
  List,
  Row,
  Col,
  Alert,
  TimePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { useCartContext } from "../../context/addToCart";
import "../../styles/placeOrder.css";
import TextArea from "antd/es/input/TextArea";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function TotalDetail() {
  const [selectedTime, setSelectedTime] = useState(
    dayjs().add(30, "minutes")
  );
  const [timeAlert, setTimeAlert] = useState(false);
  const {
    tipPrice,
    setTipPrice,
    setDeliveryTime, // assuming this is a function from useCartContext to set the delivery time
    cartValue,
    amountDetails,
  } = useCartContext();

  const handleTimeChange = (newTime, timeString) => {
    const currentTime = dayjs();
    const defaultDeliveryTime = currentTime.add(30, "minutes");
    if (
      newTime.isBefore(currentTime, "minute") ||
      newTime.isBefore(defaultDeliveryTime, "minute")
    ) {
      setTimeAlert(true);
    } else {
      setTimeAlert(false);
      setSelectedTime(newTime);
      const formattedTime = newTime.format("hh:mm A");
      setDeliveryTime(formattedTime); // Update the time in the context
    }
  };

  useEffect(() => {
    if (!selectedTime) {
      const defaultDeliveryTime = dayjs()
        
        .add(30, "minutes");
      setSelectedTime(defaultDeliveryTime);
      const formattedTime = defaultDeliveryTime
        
        .format("hh:mm A");
      setDeliveryTime(formattedTime); // Update the time in the context
    }
  }, [selectedTime, setDeliveryTime]);


  return (
    <div className="orderCard" style={{ position: "sticky", top: "150px" }}>
      <h5>Order Details</h5>

      <div className="cartList">
        {cartValue && cartValue.length > 0 ? (
          <>
            <List style={{ maxHeight: "300px" }} itemLayout="horizontal">
              {cartValue.map((value, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    avatar={<Avatar src={value.Image} />}
                    title={
                      <Row>
                        <Col span={16}>
                          <span>
                            {value.Name}
                            <br /> ({value.spice}&nbsp;spicy)
                          </span>
                        </Col>
                        <Col span={4}>
                          <span style={{ color: "#808080" }}>
                            Qty:&nbsp;{value.itemQty}
                          </span>
                        </Col>
                        <Col span={4}>
                          <span style={{ color: "#808080" }}>
                            $&nbsp;{value.price}{" "}
                          </span>
                        </Col>
                      </Row>
                    }
                  />
                </List.Item>
              ))}
            </List>
          </>
        ) : null}
      </div>

      <div className="timesection">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={[
              "TimePicker",
              "MobileTimePicker",
              "DesktopTimePicker",
              "StaticTimePicker",
            ]}
          >
            <DemoItem label="Pick Time">
              <TimePicker
                allowClear={false}
                defaultValue={selectedTime || dayjs().add(30, "minutes")}
                onChange={(time, timeString) =>
                  handleTimeChange(
                    dayjs(time),
                    timeString
                  )
                }
                format="hh:mm A"
                style={{ width: "100%" }}
              />

              {timeAlert && (
                <Alert
                  message="You have selected the wrong time. Please choose a future time."
                  type="error"
                  showIcon
                  style={{ marginTop: 10 }}
                />
              )}
              <p style={{ marginTop: 10, color: "black", fontSize: "12px" }}>
                Note: Please select the food at least 30 Mins before the desired
                delivery time.
              </p>
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </div>

      <Divider orientation="left">Pickup Note</Divider>
      <TextArea
        showCount
        maxLength={100}
        onChange={(e) => sessionStorage.setItem("pickupNote", e.target.value)}
      />

      <Divider orientation="left">Bill Details</Divider>
      <div className="billDetails">
        <div className="listRow">
          <strong>Subtotal</strong>
          <span>$ {amountDetails.subTotal}</span>
        </div>
        <div className="listRow">
          <strong>Sales Tax {amountDetails.taxRate}%</strong>
          <span>$ {amountDetails.texAmount}</span>
        </div>

        <div className="listRow">
          <strong>Tip</strong>
          <span>
            {/* <InputNumber
              addonBefore="$"
              min={2}
              max={50}
              value={tip}
              onChange={(value) => setTip(value)}
              style={{ width: 100 }}
            /> */}
            <InputNumber
              addonBefore="$"
              min={0}
              max={100}
              value={tipPrice}
              onChange={(value) => setTipPrice(value)}
              style={{ width: 100 }}
            />
          </span>
        </div>
        <Divider />
        <div className="listRow">
          <strong>Total Pay</strong>
          <span>$ {amountDetails.totalAmount}</span>
        </div>
      </div>
    </div>
  );
}
