// import Title from "../sectionTitle/title";
import "../../styles/testimonial.css";
// import TestimonialCard from "./TestimonialCard";
// import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
// import { Autoplay, Pagination } from "swiper/modules";

function TestimonialSection() {
  // const testimonialList = [
  //   {
  //     img: "",
  //     review:
  //       "Every time I eat here it is always a pleasure. Great atmosphere, food and service. I saw that my favorite appetizer dish wasn’t on the menu so to the menu being revamped and by server Savio went above and beyond to ask the chef could he make that dish.",
  //     name: "Natasha McGill",
  //   },
  //   {
  //     img: "",
  //     review:
  //       "This is my new special place to go when I desire a break from typical American food. I love the spices. You can literally taste the  culture. There are multiple vegan options as well. I can leave completely full while knowing my meal was healthier than any other restaurant nearby.",
  //     name: "Black Phoenix",
  //   },
  //   {
  //     img: "",
  //     review:
  //       "Amazing food. Dal fry, panner pasanda was amazing. it has good spicy food. Authentic Indian food with lot of spices in it. Kids friendly, ambience is very awesome. Staff is very good and friendly. Lots of variety of Indian food. We had vegetarian food, which was awesome. Very good taste. Restaurant is very neat and clean. Tawa roti and rice was also very good taste! ",
  //     name: "sandesh mavinakuli",
  //   },
  //   {
  //     img: "",
  //     review:"Amazing, delicious, phenomenal in every way. Service was just fabulous. If this review seems over the top, it's because the food and atmosphere are over the top! I loved that we could hear ourselves talk and the appropriate background music did not drown out conversation. The elegant serving pieces were beautiful, the servers were kind and helpful but never intrusive. The food was incredible. On the recommendation of my Indian friend, I ordered the butter chicken and garlic naan. It was melt-in-your-mouth delicious. I ordered the dish mild (not extra mild) and it had a wonderful flavor. The naan was hot and jut the right texture. My friend got the lamb kebob with medium spice and it had a real kick. She loved it. Overall wonderful experience.",
  //     name: "Melinda Gholson",
  //   },
  //   {
  //     img: "",
  //     review:
  //       "Wonderful service! So kind and such a welcoming atmosphere. The food was delicious; highly recommend!! I had the Chicken Tikka Masala and it was hard to stop eating haha",
  //     name: "Anna Wood",
  //   },
  //   {
  //     img: "",
  //     review:
  //       "Great service Great food I got the Masala with the 'Indian spicy' heat level It burned my mouth up pretty good but it was a wonderful meal. The additional bread that I ordered on the side was also up to par..",
  //     name: "Joseph Stone",
  //   },
  //   {
  //     img: "",
  //     review:
  //       "Indian Delight is a delight to experience. The food is amazing, the ambience is wonderful, and the service is impeccable. Hattiesburg is very lucky to have this restaurant. They could be wildly successful in a much larger market. If you enjoy Indian food at all, or if you have ever wanted to try it, I cannot recommend them highly enough. They are simply perfect in every way.",
  //     name: "Chris Carlson",
  //   },
  // ];
  return (
    <div className="testimonial-section section" id="reviews">
      {/* <Title
        text="WHAT OUR GUESTS SAY"
        desc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatibus. ehfbushbi ijfbfh gfhj uhev fhvh fvhfvhjf vhjfhuf hbfhfbhfbf  fbfbff"
      /> */}
      <div className="container">
      <div class="elfsight-app-36c2e770-5548-46c2-aef7-b9376405fa3c" data-elfsight-app-lazy></div>
        {/* <div className="testimonial-list">
          <Swiper
            style={{ padding: "35px 10px" }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              992: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <TestimonialCard img="https://templates-flatlogic.herokuapp.com/sing-app/html5/demo/img/people/a5.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialCard img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiWZb6tHFE9nQ64IIGUnluFlwYfk4D2Rql3ZUBzQ_KwHwvxLY8_Gj1Cr_Kn5Emq_O7dZQ&usqp=CAU" />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialCard img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdZSsRW8ahClgpWbdmk1wKCv_6d5ZNEf_kuZLEmarGpS7KAd8cHuXo9UPSJOy_EESmpu8&usqp=CAU" />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialCard img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxtDZAvImObxjDKS11-n0-BwpvuEEZbiIYC3qbUAorUHLBf7yz8THOXt5v67PNtv6anpE&usqp=CAU" />
            </SwiperSlide>
          </Swiper>
        </div> */}
      </div>
    </div>
  );
}

export default TestimonialSection;
