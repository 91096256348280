import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import "../styles/menu.css";
import hattis1 from "../assets/menu/hattiesburg/indian-delight-hattiesburg-1.jpg";
import hattis2 from "../assets/menu/hattiesburg/indian-delight-hattiesburg-2.jpg";
import hattis3 from "../assets/menu/hattiesburg/indian-delight-hattiesburg-3.jpg";
import hattis4 from "../assets/menu/hattiesburg/indian-delight-hattiesburg-4.jpg";
import hattis5 from "../assets/menu/hattiesburg/indian-delight-hattiesburg-5.jpg";
import hattis6 from "../assets/menu/hattiesburg/indian-delight-hattiesburg-6.jpg";
import hattis7 from "../assets/menu/hattiesburg/indian-delight-hattiesburg-7.jpg";
import hattis8 from "../assets/menu/hattiesburg/indian-delight-hattiesburg-8.jpg";
import { Image } from "antd";
import EmptyComp from "../components/Empty/empty";

export default function Menu() {
  const { branch } = useParams();

  const menuList = [
    {
      title: "Indian delight hattiesburg",
      slug: "indian-delight-hattiesburg",
      img: [
        hattis1,
        hattis2,
        hattis3,
        hattis4,
        hattis5,
        hattis6,
        hattis7,
        hattis8,
      ],
    },
    {
      title: "Indian delight ocean springs",
      slug: "indian-delight-ocean-springs",
      img: [],
    },
  ];

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      {menuList
        .filter((filtValue) => filtValue.slug === branch)
        .map((value) => (
          <div className="menu-section padding-top">
            <div className="menuBanner">
              <h1 className="maintitle">{value.title}</h1>
            </div>
            <div className="section">
              <div className="container">
                {value.img.length > 0 ? (
                  <Image.PreviewGroup>
                    <div className="row">
                      {value.img.map((vlaueimg) => (
                        <div className="col-md-6 mb-4">
                          <div className="menuCard">
                            <Image src={vlaueimg} alt={value.title} />
                          </div>{" "}
                        </div>
                      ))}
                    </div>
                  </Image.PreviewGroup>
                ) : <EmptyComp message="No Menu Found" />}
              </div>
            </div>
          </div>
        ))}
      <Footer />
    </div>
  );
}
